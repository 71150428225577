import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { SidebarComponents } from './vehicleData/SidebarComponents';
import { SidebarBrakeLinings } from './vehicleData/SidebarBrakeLinings';
import { SidebarFillLevels } from './vehicleData/SidebarFillLevels';
import { SidebarTirePressures } from './vehicleData/SidebarTirePressures';
import SidebarAppointment from '../../../components/SidebarAppointment';
import { Appointments, Asset, BrakeLinings, Components, FillLevels, TirePressures } from '../../../generated/fleetAggregationServiceTypes';
import { hasFeature } from '../../../productsConfig';
import AnalyticsEvents from '../../../utils/analytics/AnalyticsEvents';
import { HighVoltageBatteryMfe } from '../../microfrontends/HighVoltageBatteryMfe';
import { FeatureToggle, isFeatureToggleEnabled, useFeatureToggle } from '../../../hooks/useFeatureToggle';
import { SidebarEngineOil } from './vehicleData/SidebarEngineOil';
import BatteryHistoricalConditionsDialog from '../battery/BatteryHistoricalConditionDialog';

type DiagnosticsSidebarTabsProps = {
    components?: Components;
    brake_linings?: BrakeLinings;
    engine_oil?: FillLevels;
    fill_levels?: FillLevels;
    tire_pressures?: TirePressures;
    appointments: Appointments;
    openCustomComponentDialog: (componentId?: string) => void;
    openDeleteCustomComponentDialog: (componentId: string) => void;
    asset: Asset;
    workshopName?: string;
};

const DiagnosticsSidebarTabs = ({
    components,
    brake_linings,
    engine_oil,
    fill_levels,
    tire_pressures,
    appointments,
    openCustomComponentDialog,
    openDeleteCustomComponentDialog,
    asset,
    workshopName,
}: DiagnosticsSidebarTabsProps) => {
    const [tabIndex, setTabIndex] = useState<number>(0);
    const enableEngineOilDid = isFeatureToggleEnabled(FeatureToggle.ENABLE_ENGINE_OIL_DID);
    const { featureToggleValue: isHistoricalBatteryConditionsEnabled } = useFeatureToggle(FeatureToggle.ENABLE_HISTORICAL_BATTERY_CONDITIONS);
    return (
        <div>
            <ul className="margin-top-25 margin-bottom-20 nav nav-pills nav-justified">
                <li className={tabIndex === 0 ? 'active' : ''} onClick={setTabIndex.bind(null, 0)}>
                    <span>
                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.tab.vehicle_data" />
                    </span>
                </li>
                <li className={`${tabIndex === 1 ? 'active' : ''} ${!appointments.length ? 'disabled' : ''}`} onClick={goToAppointments}>
                    <span>
                        <FormattedMessage id="intl-msg:fleetstatus.global.appointments" /> {`(${appointments.length})`}
                    </span>
                </li>
            </ul>
            <div>
                {tabIndex === 0 && (
                    <>
                        {components && (
                            <>
                                <SidebarComponents
                                    {...components}
                                    openCustomComponentDialog={openCustomComponentDialog}
                                    openDeleteCustomComponentDialog={openDeleteCustomComponentDialog}
                                    asset={asset}
                                    workshopName={workshopName}
                                />
                                {hasFeature('diagnosticDetailsView', asset) && <hr className="margin-left--20 margin-right--20" />}
                            </>
                        )}
                        {brake_linings && <SidebarBrakeLinings brakeLinings={brake_linings} asset={asset} />}
                        {engine_oil && enableEngineOilDid && <SidebarEngineOil engineOil={engine_oil} asset={asset} />}
                        {fill_levels && <SidebarFillLevels fillLevels={fill_levels} asset={asset} />}
                        {hasFeature('diagnosticDetailsView', asset) && tire_pressures && <SidebarTirePressures tirePressures={tire_pressures} asset={asset} />}
                        {asset.isElectric && <HighVoltageBatteryMfe assetId={asset.id} />}
                        {asset.isElectric && isHistoricalBatteryConditionsEnabled && <BatteryHistoricalConditionsDialog assetId={asset.id} />}
                    </>
                )}
                {tabIndex === 1 && (
                    <>
                        {appointments.map((appointment) => (
                            <SidebarAppointment key={appointment.date} appointment={appointment} asset={asset} />
                        ))}
                    </>
                )}
            </div>
        </div>
    );

    function goToAppointments() {
        if (appointments.length) {
            AnalyticsEvents.openDiagnosticsSidebarAppointmentsTab(asset.product);

            setTabIndex(1);
        }
    }
};

export default DiagnosticsSidebarTabs;
